import CopilotHead from './copilot-head/copilotHead'
import common from './copilot-head/common'
import mouseMng from './copilot-head/mouseMng'
import controls from './copilot-head/controls'
import assets from './copilot-head/assets'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

const sizes = {
  width: 500,
  height: 500,
}

let assetLoaded = false

observe('.js-copilot-head', canvasElement => {
  const canvas = canvasElement as HTMLCanvasElement
  const canvasRect = canvas.getBoundingClientRect()
  sizes.width = canvasRect.width
  sizes.height = canvasRect.height

  const canvasWrapper = document.querySelector('.js-copilot-head-wrapper') as HTMLElement

  common.init(canvasWrapper, canvas)
  controls.init()
  mouseMng.init()

  const controlHead = new CopilotHead()
  if (!assetLoaded) {
    assets.load(() => {
      controlHead.init()
      assetLoaded = true
    })
  }

  common.scene.add(controlHead.group)

  // eslint-disable-next-line github/prefer-observers
  window.addEventListener('resize', () => {
    common.resize()
  })

  // eslint-disable-next-line github/prefer-observers
  window.addEventListener('scroll', () => {
    common.resize()
  })

  /**
   * Animate
   */

  const tick = () => {
    common.update()
    mouseMng.update()

    // Update controls
    controlHead.update()

    // Render
    common.renderer.render(common.scene, common.camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
  }

  tick()
})
